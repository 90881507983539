import React from "react";

export default ({ current, max }) => {
  return (
    <div className="player-health">
      <div className="health-bar">
        <div
          className="current-health"
          style={{
            background:
              (current / max) * 100 > 50
                ? "#60EA76"
                : (current / max) * 100 > 15
                ? "#EA9A60"
                : "#EA6060",
            width: (current / max) * 100 + "%",
          }}
        />
      </div>
      <div className="health-values">
        {current}/{max}
      </div>
    </div>
  );
};
