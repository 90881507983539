import React, { useState, useContext } from "react";
import firebase from "../Config/Firebase";
import { AuthContext } from "../Config/Auth";
import { Link } from "react-router-dom";
import Header from "../Components/Header";
import Input from "../Components/Input";

export default () => {
  const [name, setName] = useState("");
  const { currentUser } = useContext(AuthContext);
  const login = (event) => {
    event.preventDefault();
    return firebase
      .auth()
      .signInAnonymously()
      .then(() => {
        firebase.auth().currentUser.updateProfile({
          displayName: name,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <Header title="Cards!" />
      {currentUser ? (
        <section className="lobby">
          <Link className="button" to="/new">
            New Game
          </Link>
          <Link className="button" to="/join">
            Join Game
          </Link>
          <Link className="button" to="/settings">
            Settings
          </Link>
          <button className="button" onClick={() => firebase.auth().signOut()}>
            Logout
          </button>
        </section>
      ) : (
        <section className="lobby">
          <Input
            label="Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <button className="button" disabled={!name} onClick={login}>
            Play
          </button>
        </section>
      )}
    </>
  );
};
