import React, { useState, useEffect } from "react";
import firebase from "../Config/Firebase";
import { useParams } from "react-router-dom";
import Card from "../Components/Card";
import Loading from "../Components/Loading";
import Health from "../Components/Health";
import Skill from "../Components/Skill";
import Icon from "../Components/Icon";
import { useWindowSize } from "react-use";
import Confetti from "react-confetti";

export default ({ history }) => {
  const db = firebase.firestore();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [showHowToPlay, setShowHowToPlay] = useState(false);
  const [theme, setTheme] = useState(false);
  const [host, setHost] = useState();
  const [players, setPlayers] = useState([]);
  const [activePlayer, setActivePlayer] = useState(0);
  const [gameActive, setGameActive] = useState(false);
  const [gameHealth, setGameHealth] = useState();
  const [winner, setWinner] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [attack, setAttack] = useState(false);
  const { width, height } = useWindowSize();
  const [direction, setDirection] = useState(true);
  const [playerPosition, setPlayerPosition] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);

  // Init
  useEffect(() => {
    // Fetch data from DB
    let unsubscribe = db
      .collection("rooms")
      .doc(id)
      .onSnapshot((room) => {
        if (room.exists) {
          // Only allow joined players
          if (
            room
              .data()
              .players.filter(
                (player) => player.uid === firebase.auth().currentUser.uid
              ).length !== 1
          ) {
            history.push(`/`);
          }

          // Check room has space
          if (room.data().players.length >= 4) {
            console.log("Room is full!");
            //history.push(`/`);
          } else {
            setGameActive(room.data().active);
            setActivePlayer(room.data().activePlayer);
            setGameHealth(room.data().health);
            setWinner(room.data().winner);
            setAttack(room.data().attack);
            setDirection(room.data().direction);
            setHost(room.data().host);
            setPlayers(room.data().players);
            setPlayerPosition(
              room
                .data()
                .players.findIndex(
                  (player) => player.uid === firebase.auth().currentUser.uid
                )
            );
            // Fetch game theme
            db.collection("themes")
              .doc(room.data().theme.id)
              .get()
              .then((theme) => {
                setTheme(theme.data());
                setLoading(false);
              });
          }
        } else {
          // Invalid ID - Redirect
          history.push(`/`);
        }
      });
    return () => unsubscribe;
  }, []);

  const nextPlayer = (currentPlayer, direction) => {
    if (direction) {
      if (currentPlayer !== players.length - 1) {
        if (players[currentPlayer + 1].active) {
          setActivePlayer(currentPlayer + 1);
          db.collection("rooms")
            .doc(id)
            .update({
              activePlayer: currentPlayer + 1,
            });
        } else {
          nextPlayer(currentPlayer + 1, true);
        }
      } else {
        if (players[0].active) {
          db.collection("rooms").doc(id).update({
            activePlayer: 0,
          });
        } else {
          nextPlayer(0, true);
        }
      }
    } else {
      if (currentPlayer === 0) {
        if (players[players.length - 1].active) {
          setActivePlayer(players.length);
          db.collection("rooms")
            .doc(id)
            .update({
              activePlayer: players.length - 1,
            });
        } else {
          nextPlayer(players.length - 1, false);
        }
      } else {
        if (players[currentPlayer - 1].active) {
          setActivePlayer(currentPlayer + 1);
          db.collection("rooms")
            .doc(id)
            .update({
              activePlayer: currentPlayer - 1,
            });
        } else {
          nextPlayer(currentPlayer - 1, false);
        }
      }
    }
    setDisableButtons(false);
  };

  const drawCard = (special) => {
    setDisableButtons(true);

    // Get random number
    let cardValue = Math.floor(Math.random() * 10);

    // Check for curve ball
    if (special) {
      cardValue = special;
      if (special === "health") {
        // 50% health
        players[activePlayer].skills.health = false;
        players[activePlayer].health = Math.floor(
          players[activePlayer].health + gameHealth / 2 >= gameHealth
            ? gameHealth
            : players[activePlayer].health + gameHealth / 2
        );
        players[activePlayer].health =
          players[activePlayer].health < 1 ? 0 : players[activePlayer].health;
      } else if (special === "attack") {
        // Prep attack
        players[activePlayer].skills.attack = false;
        db.collection("rooms").doc(id).update({
          attack: true,
        });
      } else if (special === "reverse") {
        players[activePlayer].skills.reverse = false;
        setDirection(!direction);
        players[activePlayer].skills.reverse = false;
      }
    } else {
      // Check for attack
      if (attack === true) {
        setAttack(false);
        // Reset attack
        db.collection("rooms").doc(id).update({
          attack: false,
        });
        cardValue = 9;
      }
      players[activePlayer].health = players[activePlayer].health - cardValue;
    }

    // Update health
    players[activePlayer].cards.push(cardValue);

    // Check for targets
    if (players[activePlayer].health <= 0) {
      // Player is out
      // Set health to 0
      players[activePlayer].health = 0;
      players[activePlayer].active = false;
      setPlayers(players);
    }

    db.collection("rooms")
      .doc(id)
      .update({
        direction: special === "reverse" ? !direction : direction,
        players: players,
      })
      .then(() => {
        // Update active player
        nextPlayer(
          activePlayer,
          special === "reverse" ? !direction : direction
        );

        // Check for winner
        let activePlayers = players.filter((player) => player.active === true);
        if (activePlayers.length === 1) {
          console.log("We have a winner", activePlayers[0]);
          db.collection("rooms").doc(id).update({
            winner: activePlayers[0].uid,
          });
        }
      });
  };

  const leaveGame = () => {
    if (window.confirm("Are you sure you want to leave the game?")) {
      let remainingPlayers = players.filter(
        (player) => player.uid !== firebase.auth().currentUser.uid
      );
      if (
        host === firebase.auth().currentUser.uid ||
        remainingPlayers.length === 0
      ) {
        // Nobody left or host
        // Delete the game
        db.collection("rooms")
          .doc(id)
          .delete()
          .then(function () {
            // Room deleted
          });
      } else if (remainingPlayers.length === 1) {
        // One player left
        // Reset the game
        endGame();
      }
      db.collection("rooms")
        .doc(id)
        .update({
          players: remainingPlayers,
        })
        .then(() => {
          setShowMenu(false);
          history.push(`/`);
        });
    }
  };

  const startGame = () => {
    // Start the game
    db.collection("rooms").doc(id).update({
      active: true,
    });
  };

  const endGame = () => {
    // Reset each player
    players.forEach((player, index) => {
      player.active = true;
      player.health = gameHealth;
      player.cards = [];
      player.skills = {
        health: true,
        attack: true,
        defend: true,
        reverse: true,
      };
    });
    // Reset room
    db.collection("rooms")
      .doc(id)
      .update({
        active: false,
        activePlayer: 0,
        direction: true,
        players: players,
        winner: false,
        attack: false,
      })
      .then(() => {
        setShowMenu(!showMenu);
      });
  };

  const myTurn = () => {
    return activePlayer === playerPosition ? true : false;
  };

  return loading || !theme || !host || !players ? (
    <Loading />
  ) : (
    <section className="game">
      <div
        className={`game-menu`}
        style={{ display: showMenu ? "grid" : "none" }}
      >
        <div className="modal-container">
          {host === firebase.auth().currentUser.uid && (
            <>
              <div>
                <button className="button" onClick={endGame}>
                  End Game
                </button>
              </div>
            </>
          )}
          <div>
            <button className="button" onClick={leaveGame}>
              Leave Game
            </button>
          </div>
          <div>
            <button className="button" onClick={() => setShowMenu(false)}>
              Close
            </button>
          </div>
        </div>
      </div>

      <div
        className={`game-menu`}
        style={{ display: showHowToPlay ? "grid" : "none" }}
      >
        <div className="modal-container how-to-play">
          <div>
            <p>How to play</p>
            <p>Take turns to draw cards</p>
            <p>Use special cards to change the game and defeat opponents</p>
            <p>The last player standing wins!</p>
            <p>
              <span>
                <Icon name="attack" />
                Attack
              </span>
              Force next player to pick up nine
            </p>
            <p>
              <span>
                <Icon name="defend" />
                Defend
              </span>
              Guarantee yourself zero
            </p>
            <p>
              <span>
                <Icon name="health" />
                Health
              </span>
              Recover 50% of your health
            </p>
            <p>
              <span>
                <Icon name="reverse" />
                Reverse
              </span>
              Reverse the direction of play
            </p>
            <button className="button" onClick={() => setShowHowToPlay(false)}>
              Close
            </button>
          </div>
        </div>
      </div>

      <div className="menu">
        <div>
          <p># {id}</p>
        </div>
        <div>
          <svg
            viewBox="0 0 24 24"
            onClick={() => setShowHowToPlay(!showHowToPlay)}
          >
            <path
              fill="currentColor"
              d="M15.07,11.25L14.17,12.17C13.45,12.89 13,13.5 13,15H11V14.5C11,13.39 11.45,12.39 12.17,11.67L13.41,10.41C13.78,10.05 14,9.55 14,9C14,7.89 13.1,7 12,7A2,2 0 0,0 10,9H8A4,4 0 0,1 12,5A4,4 0 0,1 16,9C16,9.88 15.64,10.67 15.07,11.25M13,19H11V17H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z"
            />
          </svg>
          <svg viewBox="0 0 24 24" onClick={() => setShowMenu(!showMenu)}>
            <path
              fill="currentColor"
              d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z"
            />
          </svg>
        </div>
      </div>

      {players.length < 2 ? (
        <div className="message">
          <p>You need at least two players</p>
          <p>Others can join using the code {id}</p>
        </div>
      ) : (
        !gameActive && (
          <>
            {host === firebase.auth().currentUser.uid ? (
              <div className="host-actions">
                <button className="button" onClick={() => startGame()}>
                  Start Game
                </button>
              </div>
            ) : (
              <div className="message">
                <p>Waiting for host to start the game</p>
              </div>
            )}
          </>
        )
      )}

      <div className="players">
        {players.map((player, index) => (
          <div key={index}>
            {winner === firebase.auth().currentUser.uid && (
              <Confetti width={width} height={height} style={{ zIndex: 999 }} />
            )}

            <div
              key={index}
              className={`player ${
                gameActive && index === activePlayer ? "active" : null
              } ${player.health <= 0 ? "out" : null}`}
            >
              {!winner &&
                index === activePlayer &&
                player.uid !== firebase.auth().currentUser.uid && (
                  <div className="player-loading">
                    <Loading />
                  </div>
                )}
              <div className="player-top">
                <div className="player-name">
                  {player.name}
                  {winner === player.uid ? (
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M5 16L3 5L8.5 10L12 4L15.5 10L21 5L19 16H5M19 19C19 19.6 18.6 20 18 20H6C5.4 20 5 19.6 5 19V18H19V19Z"
                      />
                    </svg>
                  ) : player.health <= 0 ? (
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M12,2A9,9 0 0,0 3,11C3,14.03 4.53,16.82 7,18.47V22H9V19H11V22H13V19H15V22H17V18.46C19.47,16.81 21,14 21,11A9,9 0 0,0 12,2M8,11A2,2 0 0,1 10,13A2,2 0 0,1 8,15A2,2 0 0,1 6,13A2,2 0 0,1 8,11M16,11A2,2 0 0,1 18,13A2,2 0 0,1 16,15A2,2 0 0,1 14,13A2,2 0 0,1 16,11M12,14L13.5,17H10.5L12,14Z"
                      />
                    </svg>
                  ) : (
                    false
                  )}
                </div>
                {gameActive && players.length > 1 && (
                  <div className="player-score">
                    <Health current={player.health} max={gameHealth} />
                  </div>
                )}
              </div>
              {gameActive && players.length > 1 && (
                <>
                  <div className="player-cards">
                    {player.cards.length ? (
                      player.cards
                        .map((card, index) => (
                          <Card
                            key={index}
                            zIndex={index}
                            value={card}
                            image={
                              theme.values[card] ? theme.values[card] : false
                            }
                            defend={player.skills.defend}
                          />
                        ))
                        .reverse()
                    ) : (
                      <Card first />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      </div>

      {showMenu ||
        showHowToPlay ||
        (gameActive && players.length > 1 && (
          <div className="controls">
            <div className="skills">
              <button
                disabled={disableButtons || winner || !myTurn()}
                className="button"
                onClick={() => drawCard(false)}
              >
                <Icon name="draw" />
              </button>
              {Object.entries(players[playerPosition].skills)
                .sort()
                .map(([skill, used]) => {
                  return (
                    <Skill
                      key={skill}
                      skill={skill}
                      onClick={() => drawCard(skill)}
                      disabled={
                        disableButtons || !myTurn() || winner || !used || attack
                      }
                    />
                  );
                })}
            </div>
          </div>
        ))}
    </section>
  );
};
