import React from "react";
import Icon from "./Icon";

export default ({ zIndex, value, first = false, image }) => {
  return (
    <div
      className={`card ${first ? "first" : null}`}
      style={{ zIndex: zIndex }}
    >
      <div className="value">{!Number.isInteger(value) ? false : value}</div>
      <div>
        {!Number.isInteger(value) ? (
          <Icon name={value} />
        ) : image ? (
          <img src={image} alt="" />
        ) : (
          value
        )}
      </div>
    </div>
  );
};
