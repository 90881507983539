import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthProvider } from "./Config/Auth";
import Home from "./Screens/Home";
import New from "./Screens/New";
import Join from "./Screens/Join";
import Settings from "./Screens/Settings";
import Game from "./Screens/Game";
import Loading from "./Components/Loading";
import "normalize.css";
import "./App.scss";

import firebase from "./Config/Firebase";

export default () => {
  const db = firebase.firestore();
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState(false);
  const [themes, setThemes] = useState(false);

  // Init
  useEffect(() => {
    // Fetch global settings
    db.collection("settings")
      .doc("settings")
      .get()
      .then((settings) => {
        setSettings(settings.data());
        // Fetch themes
        let themes = [];
        db.collection("themes")
          .where("active", "==", true)
          .orderBy("name")
          .get()
          .then((results) => {
            results.forEach((theme) => {
              themes.push({
                value: theme.ref,
                label: theme.data().name,
              });
            });
            setThemes(themes);
            setLoading(false);
          });
      });
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <AuthProvider>
      <Router>
        <Switch>
          <Route
            path="/new"
            component={() => <New settings={settings} themes={themes} />}
            test="Test"
          />
          <Route path="/join/:id" component={Join} />
          <Route path="/join" component={Join} />
          <Route path="/settings" component={Settings} />
          <Route path="/game/:id" component={Game} />
          <Route exact path="/" component={Home} />
        </Switch>
      </Router>
    </AuthProvider>
  );
};
