import React from "react";

export default ({ type, label, value, onChange }) => {
  return (
    <div className="input">
      <label>{label}</label>
      <input type={type ? type : "text"} value={value} onChange={onChange} />
    </div>
  );
};
