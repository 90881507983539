import React, { useState } from "react";
import firebase from "../Config/Firebase";
import { Link } from "react-router-dom";
import Header from "../Components/Header";
import { useParams } from "react-router-dom";
import Input from "../Components/Input";
import Loading from "../Components/Loading";

export default ({ history, user }) => {
  const db = firebase.firestore();
  const { id } = useParams();
  const [joinID, setJoinID] = useState(id);
  const [message, setMessage] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);

  const joinRoom = () => {
    setDisableButtons(true);
    if (joinID !== null && joinID !== "") {
      db.collection("rooms")
        .doc(joinID)
        .get()
        .then((room) => {
          // Check if room exists
          if (room.exists) {
            // Check if current user already joined
            if (
              room
                .data()
                .players.some(
                  (player) => player.uid === firebase.auth().currentUser.uid
                )
            ) {
              history.push(`/game/${joinID}`);
            } else {
              // Check room isn't active
              if (room.data().active) {
                setDisableButtons(false);
                setMessage("This game has already started");
              } else {
                // Check room has space
                if (room.data().players.length >= 4) {
                  setDisableButtons(false);
                  setMessage("The game has too many players");
                } else {
                  // Update players
                  let players = room.data().players;
                  players.push({
                    active: true,
                    cards: [],
                    name: firebase.auth().currentUser.displayName,
                    health: room.data().health,
                    skills: {
                      health: true,
                      attack: true,
                      defend: true,
                      reverse: true,
                    },
                    uid: firebase.auth().currentUser.uid,
                  });
                  // Join room
                  db.collection("rooms")
                    .doc(joinID)
                    .update({
                      players: players,
                    })
                    .then((race) => {
                      // Redirect to room
                      history.push(`/game/${joinID}`);
                    })
                    .catch(function (error) {
                      console.error(error);
                    });
                }
              }
            }
          } else {
            setDisableButtons(false);
            setMessage("Room not found");
          }
        });
    }
  };

  return (
    <>
      <Header title="Join Game" />
      <section className="lobby">
        {message && <div className="message">{message}</div>}
        <Input
          type="tel"
          label="Join Code"
          value={joinID}
          onChange={(e) => setJoinID(e.target.value)}
        />
        <button className="button" disabled={!joinID} onClick={joinRoom}>
          {disableButtons ? <Loading /> : "Join"}
        </button>
        <Link className="button" to="/">
          Cancel
        </Link>
      </section>
    </>
  );
};
