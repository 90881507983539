import React, { useState } from "react";
import firebase from "../Config/Firebase";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import Toggle from "../Components/Toggle";
import Header from "../Components/Header";
import Loading from "../Components/Loading";

const New = ({ history, settings, themes }) => {
  const db = firebase.firestore();
  const [gameHealth, setGameHealth] = useState();
  const [gameTheme, setGameTheme] = useState();
  const [disableButtons, setDisableButtons] = useState(false);

  const newRoom = () => {
    setDisableButtons(true);
    // Generate a four digit ID
    let id = Math.floor(1000 + Math.random() * 9000).toString();
    // Create a new room
    db.collection("rooms")
      .doc(id)
      .get()
      .then((room) => {
        // Check room doesn't already exist
        if (!room.exists) {
          db.collection("rooms")
            .doc(id)
            .set({
              active: false,
              activePlayer: 0,
              created: new Date(),
              direction: true,
              host: firebase.auth().currentUser.uid,
              players: [
                {
                  active: true,
                  cards: [],
                  name: firebase.auth().currentUser.displayName,
                  health: gameHealth,
                  skills: {
                    health: true,
                    attack: true,
                    defend: true,
                    reverse: true,
                  },
                  uid: firebase.auth().currentUser.uid,
                },
              ],
              health: gameHealth,
              theme: gameTheme,
              winner: false,
            })
            .then((race) => {
              // Redirect to room
              history.push(`/game/${id}`);
            })
            .catch(function (error) {
              // Room already exists
              console.error("Room not created!", error);
            });
        }
      })
      .catch((error) => {
        setDisableButtons(false);
        console.error(error);
      });
  };

  return (
    <>
      <Header title="New Game" />
      <section className="lobby">
        <Toggle
          label="Starting Health"
          options={settings.health}
          onChange={(option) => setGameHealth(option.value)}
        />
        <Toggle
          label="Card Theme"
          options={themes}
          onChange={(option) => setGameTheme(option.value)}
        />
        <button className="button" onClick={newRoom} disabled={disableButtons}>
          {disableButtons ? <Loading /> : "Start"}
        </button>
        <Link className="button" to="/">
          Cancel
        </Link>
      </section>
    </>
  );
};

export default withRouter(New);
