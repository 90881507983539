import React from "react";
import Icon from "./Icon";

export default ({ skill, onClick, disabled, ref }) => {
  return (
    <button
      ref={ref}
      className={`button ${skill}`}
      disabled={disabled}
      onClick={onClick}
    >
      <Icon name={skill} />
    </button>
  );
};
